<template>
  <div class="flex">
    <VueDatePicker v-model="searchDate.date"
                   model-type="yyyy-MM-dd"
                   format="yyyy-MM-dd"
                   locale="ru-RU"
                   range
                   select-text="Выбрать"
                   cancel-text="Отменить"
                   @cleared="cleared"
                   placeholder="Выберите диапазон">
    </VueDatePicker>
  </div>
  <div class="filters">
    <custom-input
        type="text"
        label="Поиск по имени"
        :label-width="30"
        :delay="800"
        name="name"
        v-model="nameFilter"
    />
  </div>
  <div v-if="loaded && usersWhiteList">
    <div v-if="shownData.length">
      <base-table
          v-if="shownData.length"
          :columns="usersWhiteListColumns"
          :rows="shownData"
      />
      <table-pagination
          v-if="shownData.length"
          :key="updatePagination"
          :totalPages="1"
          :countOnPage="countOnPage"
          :total="totalResults ?? rowData.length"
          :count="countOnPage > shownData.length ? shownData.length : countOnPage"
          @changePage="changePage"
      />
    </div>
    <div v-else class="no_results">
      <p>Нет результатов</p>
    </div>
  </div>
  <div v-else class="table-preloader">
    <preloader/>
  </div>
</template>

<script>
import {reactive, ref} from "@vue/reactivity";
import {useStore} from "vuex";
import {computed, onBeforeMount, watch} from "@vue/runtime-core";
import Preloader from "../Technical/Preloader.vue";
import ShowCountTest from "./CellRenderers/ShowCountTest.vue";
import VueDatePicker from '@vuepic/vue-datepicker';
import '@vuepic/vue-datepicker/dist/main.css'
import BaseTable from "@/components/Tables/BaseTable.vue";
import TablePagination from "@/components/OtherElements/TablePagination.vue";
import CustomInput from "@/components/Forms/Fields/CustomInput.vue";

export default {
  name: "user-white-list-table",
  components: {
    CustomInput,
    TablePagination, BaseTable,
    Preloader,
    ShowCountTest,
    VueDatePicker
  },
  setup() {
    const store = useStore();
    const countOnPage = ref(10),
        pageDataStart = ref(0),
        loaded = ref(false),
        nameFilter = ref('');
    const dateTo = new Date();
    const dateFrom = new Date(new Date().setDate(dateTo.getDate() - 7));

    const formatDate = (date) => {
      let day = date.getDate();
      let month = date.getMonth() + 1;
      return date.getFullYear() + '-' + (month < 10 ? '0' + month : month) + '-' + (day < 10 ? '0' + day : day);
    }

    const formattedDateFrom = formatDate(dateFrom);
    const formattedDateTo = formatDate(dateTo);
    const searchDate = reactive({date: [formattedDateFrom, formattedDateTo]});

    const usersWhiteList = computed(() => store.state.users.usersWhiteList),
        rowData = computed(() => {
          return usersWhiteList.value.map((user) => {
            return {
              id: user.id,
              name: user.name,
              full: {
                full_active_count: user.full_active_count,
                full_free_count: user.full_free_count,
                full_issued_count: user.full_issued_count,
                full_total: user.full_total
              },
              standard: {
                standard_active_count: user.standard_active_count,
                standard_free_count: user.standard_free_count,
                standard_issued_count: user.standard_issued_count,
                standard_total: user.standard_total,
              }
            };
          });
        });

    const usersWhiteListColumns = [
      {
        field: "name",
        headerName: "Имя",
      },
      {
        field: "test_count",
        type: "test",
      },
      {
        field: "test_count",
        headerName: "Проходят тесты",
        type: "active",
      },
      {
        field: "test_count",
        headerName: "Неначатых тестов",
        type: "free",
      },
      {
        field: "test_count",
        headerName: "Пройдено тестов",
        type: "issued",
      },
      {
        field: "test_count",
        headerName: "Всего тестов",
        type: "total",
      },
    ];

    const updatePagination = ref(false);
    const totalResults = ref();

    const shownData = computed(() => {
      let data = rowData.value ?? [];

      if (data.length) {

        if (nameFilter.value && nameFilter.value.length >= 3) {
          data = data.filter((el) => el.name.toLowerCase().includes(nameFilter.value.toLowerCase()));
        }

        totalResults.value = data.length;
        data = data.slice(pageDataStart.value, pageDataStart.value + countOnPage.value);
      }

      return data;
    });

    const changePage = (page) => {
      pageDataStart.value = (page - 1) * countOnPage.value;
    };

    const getUsers = () => {
      loaded.value = false;
      store.dispatch("users/getUsersWhiteList", {
        date: searchDate.date[0] + ',' + searchDate.date[1],
      }).then(() => {
        loaded.value = true;
      }).catch(() => {
        loaded.value = true;
      });
    }

    onBeforeMount(() => {
      getUsers()
    });

    watch(() => searchDate.date, () => {
      getUsers();
      totalResults.value = 0;
      pageDataStart.value = 0;
      updatePagination.value = !updatePagination.value;
    });

    watch(() => nameFilter.value, () => {
      totalResults.value = 0;
      pageDataStart.value = 0;
      updatePagination.value = !updatePagination.value;
    });

    const cleared = () => {
      getUsers()
    }

    return {
      countOnPage,
      loaded,
      changePage,
      usersWhiteList,
      usersWhiteListColumns,
      rowData,
      searchDate,
      cleared,
      shownData,
      updatePagination,
      totalResults,
      nameFilter,
    };
  },
};
</script>

<style lang="scss" scoped>
.no_results {
  padding: 100px 0;
  text-align: center;
}

.filters {
  margin: 25px 0;
  width: 500px
}

@media (max-width: 930px) {
  .filters {
    margin: 10px 0;
    width: 100%;
  }
}
</style>

<style>
.dp__action_button {
  padding-bottom: 25px;
  color: #ffffff;
  background-color: #4d9477;
}

.dp__action_button:hover {
  background-color: #00a65a;
}

.dp__range_end, .dp__range_start, .dp__active_date {
  background-color: #00a65a;
}

.dp__today {
  border: 1px solid #00e287;
}
</style>
